<script setup lang="ts">
import { mdiArrowRight, mdiChevronDown } from "@mdi/js";

enum OverlayName {
  Agency = "agency",
}

const { t } = useI18n();
const localeRoute = useLocaleRoute();
const route = useRoute();

const isAgencyPanelOpen = ref(true);

watch(
  () => route.fullPath,
  () => {
    // since we only have one expansion panel we don't close it after routing
    // isAgencyPanelOpen.value = false;
  },
);
</script>

<template>
  <navigation-bar>
    <template #desktop-buttons="{ activeOverlay }">
      <HwButton
        :icon="[mdiChevronDown]"
        :iconClass="{
          'icon--rotated': activeOverlay === OverlayName.Agency,
        }"
        :title="t('mainMenu.button.agency')"
        class="h-100"
        half-padding
        :value="OverlayName.Agency"
        :aria-expanded="activeOverlay === OverlayName.Agency"
      />
    </template>

    <template #desktop-items="{ activeOverlay }">
      <div
        v-if="activeOverlay === OverlayName.Agency"
        class="desktop-navigation__grid desktop-navigation__card-row"
      >
        <NavigationMenuCard
          :card-description="t('mainMenu.saxonyAi.aboutUs.description')"
          :card-title="t('mainMenu.saxonyAi.aboutUs.title')"
          :to="localeRoute({ name: 'about' })"
          class="desktop-navigation__grid__item"
          image-path="/images/Logo_Saxony_Ai_M_Gradient.svg"
        />

        <!--                <NavigationMenuCard-->
        <!--                  :card-title="t('mainMenu.agency.team.title')"-->
        <!--                  :card-description="t('mainMenu.agency.team.description')"-->
        <!--                  image-path="images/Team.svg"-->
        <!--                  :image-color="'#1574ad'"-->
        <!--                  :to="-->
        <!--                    localeRoute({-->
        <!--                      name: 'employees',-->
        <!--                    })-->
        <!--                  "-->
        <!--                  class="desktop-navigation__grid__item"-->
        <!--                />-->

        <NavigationMenuCard
          :card-description="t('mainMenu.agency.ourPrinciples.description')"
          :card-title="t('mainMenu.agency.ourPrinciples.title')"
          :to="localeRoute({ name: 'about', hash: '#our-principles' })"
          class="desktop-navigation__grid__item"
          image-path="/images/Technologies.svg"
          image-color="#77d3d1"
          :image-classes="['rounded-circle']"
        />
      </div>
    </template>

    <!--    <template #desktop-additional-items>-->
    <!--      <HwButton-->
    <!--        :icon="[mdiArrowRight]"-->
    <!--        :title="t('mainMenu.button.readBlog')"-->
    <!--        :to="localeRoute({ name: 'blog' })"-->
    <!--        color="#FFFFFF"-->
    <!--        full-->
    <!--        underline-->
    <!--      />-->
    <!--    </template>-->

    <template #mobile-navigation>
      <expansion-panel
        :title="t('mainMenu.button.agency')"
        v-model="isAgencyPanelOpen"
        position="right"
        simple-chevron
        :button-underline="false"
      >
        <template #default>
          <NavigationMobileMenuCard
            :card-description="t('mainMenu.saxonyAi.aboutUs.description')"
            :card-title="t('mainMenu.saxonyAi.aboutUs.title')"
            image-path="/images/Logo_Saxony_Ai_M_Gradient.svg"
            class="elevation-4 mt-2"
            :image-classes="['mobile-navigation__card--image-padding']"
            :to="
              localeRoute({
                name: 'about',
              })
            "
          />

          <!--                <NavigationMobileMenuCard-->
          <!--                  :card-title="t('mainMenu.agency.team.title')"-->
          <!--                  :card-description="t('mainMenu.agency.team.description')"-->
          <!--                  image-path="/images/Team.svg"-->
          <!--                  image-color="#1574ad"-->
          <!--                  :to="-->
          <!--                    localeRoute({-->
          <!--                      name: 'employees',-->
          <!--                    })-->
          <!--                  "-->
          <!--                  class="hw-mt-small"-->
          <!--                />-->

          <NavigationMobileMenuCard
            :card-title="t('mainMenu.agency.ourPrinciples.title')"
            :card-description="t('mainMenu.agency.ourPrinciples.description')"
            image-path="/images/Technologies.svg"
            :image-classes="[
              'rounded-circle',
              'mobile-navigation__card--image-padding',
            ]"
            image-color="#77d3d1"
            :to="
              localeRoute({
                name: 'about',
                hash: '#our-principles',
              })
            "
            class="hw-mt mb-3 elevation-4"
          />
        </template>
      </expansion-panel>
    </template>
  </navigation-bar>
</template>

<style>
.mobile-navigation__card--image-padding {
  padding: 0.75rem !important;
}

.mobile-navigation__card__text::before {
  background: transparent
    linear-gradient(90deg, #f26d85 0%, #fa8f3c 33%, #82d9d0 67%, #30a9d9 100%)
    0% 0% no-repeat padding-box !important;
}

.expansion-panel-header {
  margin-right: var(--spacer-x);
}
</style>
